<template>
  <div class="user">
    <div class="title">{{ editFlag ? '修改话题' : '新增话题' }}</div>
    <div class="item_content">
      <el-form :model="queryInfo" label-width="100px" ref="form" :rules="rules">
        <el-form-item label="话题类型:" prop="topicType">
          <el-radio-group v-model="queryInfo.topicType">
            <el-radio :label="0">图文话题</el-radio>
            <el-radio :label="1">文字话题</el-radio>
            <el-radio :label="2">组图话题</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="图片">
          <div class="ImgArea">
            <div class="showImg">
              <upload-image
                :fileList="imageUrlList"
                @updateImgTwo="updateImgTwo"
                @updateImgShowTwo="updateImgShowTwo"
                @removeImg="emitImgRemove"
              />
            </div>
          </div>
        </el-form-item>
        <div class="bottom_tip">(图片少于12MB，图片建议上传2~3张)</div>

        <el-form-item label="话题描述" prop="topicDescribe">
          <div class="editBar">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="queryInfo.topicDescribe"
              maxlength="120"
              show-word-limit
            >
            </el-input>
            <!-- <editorbar v-model="queryInfo.topicDescribe" :isClear="isClear"/> -->
          </div>
        </el-form-item>
        <!-- <div class="bottom_tip"> -->
        <!-- (图片的建议尺寸长为750px，富文本内图片格式应为jpg、jpeg、png，图片应小于12MB) -->
        <!-- </div> -->
      </el-form>
      <div class="btn_area">
        <div class="btn" @click="editOne()" v-if="editFlag">修改</div>
        <div class="btn" @click="addOne('form')" v-if="!editFlag">保存</div>
        <div class="btn" @click="outPlace()" style="margin-left: 20px">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadImage from '@/components/UploadImage.vue'
// import Editorbar from '../../../../components/Editorbar.vue';
import { getDetail, editBusinessTopic, addBusinessTopic } from '@/api/businessTopics.js'
export default {
  components: { UploadImage },
  data() {
    return {
      queryInfo: {},
      imgUrlShow: [],
      imageUrlList: [],
      imgList: [],
      editFlag: false,
      isClear: false,
      rules: {
        topicType: [{ required: true, message: '请选择话题类型', trigger: 'change' }]
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id)
      this.editFlag = true
    }
  },
  methods: {
    async getDetail(id) {
      const { data: res } = await getDetail({ id: id })
      this.queryInfo = res.data
      this.imageUrlList = res.data.imgList.map((el) => {
        return {
          url: el,
          name: el
        }
      })
    },
    addOne(formName) {
      this.queryInfo.imgList = []
      this.imageUrlList.map((item) => {
        this.queryInfo.imgList.push(typeof item.url == 'object' ? item.url[0] : item.url)
      })
      // console.log(this.queryInfo.imgList," console.log(this.queryInfo);");
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await addBusinessTopic(this.queryInfo)
          if (res.resultCode === 200) {
            // console.log(res);
            this.$message.success('添加成功!')
            this.outPlace()
          }
        } else {
          this.$message.error('请填写必要的选项!')
          return false
        }
      })
    },
    outPlace() {
      this.$router.push({
        path: `/businessHub/businessTopics`
      })
    },
    async editOne() {
      this.queryInfo.imgList = []
      this.imageUrlList.map((item) => {
        this.queryInfo.imgList.push(typeof item.url == 'object' ? item.url[0] : item.url)
      })

      const { data: res } = await editBusinessTopic(this.queryInfo)
      if (res.resultCode === 200) {
        this.$message.success('修改成功!')
        this.outPlace()
      }
    },
    //多个图片上传
    updateImgTwo(val) {
      this.imgList = val
      // console.log(this.imgList,'imgList');
    },
    //多个图片上传显示
    updateImgShowTwo(val) {
      this.imageUrlList = val
      // console.log(this.imageUrlList,"updateImgShowTwo");
    },
    //多个图片上传移除
    emitImgRemove(val) {
      this.imageUrlList = this.imageUrlList.filter((item) => {
        return item !== val
      })
      // console.log( this.imageUrlList,"ad");
    }
  }
}
</script>

<style scoped lang="less">
.user {
  /deep/.w-e-text-container {
    z-index: 1 !important;
  }

  /deep/.w-e-menu {
    z-index: 2 !important;
  }

  /deep/.w-e-toolbar {
    z-index: 2 !important;
  }
  width: 100%;
  overflow: hidden;
  height: auto !important;
  margin: 15px 0px 0px 20px;
  border-radius: 12px;
  background: #ffffff;
  .item_content {
    margin: 20px 0px 0px 20px;
    .bottom_tip {
      font-size: 12px;
      color: #7c71e1;
      margin: 10px 0px 40px 100px;
    }
    .editBar {
      width: 800px;
      height: 108px;
      /deep/.el-textarea__inner {
        height: 108px;
      }
    }
  }
  .title {
    width: 100%;
    height: 40px;
    text-indent: 1.5em;
    border-bottom: 1px solid #efefef;
    margin: 16px 20px 0px 20px;
    font-size: 18px;
    line-height: 25px;
    color: #968de2;
  }
}
.ImgArea {
  display: flex;
  position: relative;
  .cancel {
    cursor: pointer;
    position: absolute;
    font-size: 18px;
    top: -5px;
    right: 5px;
  }
}
.btn_area {
  margin: 250px 0 100px 60px;
  width: 100%;
  display: flex;
  .btn {
    color: #fff;
    text-align: center;
    line-height: 40px;
    width: 136px;
    height: 40px;
    background: #7c71e1;
    border-radius: 4px;
    cursor: pointer;
  }
}
</style>
